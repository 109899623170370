import React from "react";
import {
  Container,
  //Text,
  Image,
  Node,
  Typescript,
  Javascript,
  Json,
  RReact,
  Figma,
} from "./styles";

const Content: React.FC = () => {
  return (
    <Container>
      <Image />
    </Container>
  );
};
export default Content;

/**
 * 
  <Node />
  <Typescript />
  <Json />
  <Javascript />
  <Figma />
  <RReact />
 * 
 * 
 */
