import styled from "styled-components";

export const Container = styled.div`
  grid-area: AS;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  padding-left: 20px;
  border-right: 0px solid ${(props) => props.theme.colors.gray};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70px;
`;

export const LogImg = styled.img`
  width: 40px;
  height: 40px;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.white};
  padding-right: 10px;
`;

export const MenuConteiner = styled.nav`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const MenuItemLink = styled.a`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.info};
  text-decoration: none;
  margin: 7px 0;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.6;
  }

  > svg {
    font-size: 25px;
    margin-right: 10px;
  }
`;
