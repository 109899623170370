import styled, { keyframes } from "styled-components";

import img from "../../assets/vscode.jpg";
import node from "../../assets/Node.png";
//import css3 from "../../assets/css3.png";
import figma from "../../assets/Figma.png";
//import html5 from "../../assets/html.png";
import javascript from "../../assets/Javascript.png";
import typescript from "../../assets/Typescript.png";
import react from "../../assets/React.png";
import json from "../../assets/JSON.png";

export const Container = styled.div`
  grid-area: CT;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  padding: 10px;
  position: relative;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: 30px;
`;

export const Image = styled.div`
  border: 0px solid #000;
  background-image: url(${img});
  position: relative;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  filter: blur(2px);
  z-index: 2;
`;

export const RReact = styled.div`
  border: 0px;
  background-image: url(${react});
  width: 180px;
  height: 110px;
  left: 50px;
  top: 50px;
  background-size: cover;
  filter: blur(1px);
  position: absolute;
  z-index: 18;
`;

export const Figma = styled.div`
  border: 0px;
  background-image: url(${figma});
  width: 180px;
  height: 110px;
  background-size: cover;
  filter: blur(1px);
  position: relative;
  z-index: 14;
`;

export const Json = styled.div`
  border: 0px;
  background-image: url(${json});
  width: 180px;
  height: 110px;
  background-size: cover;
  filter: blur(1px);
  position: relative;
  z-index: 15;
`;

export const Javascript = styled.div`
  border: 0px;
  background-image: url(${javascript});
  width: 180px;
  height: 110px;
  background-size: cover;
  filter: blur(1px);
  position: relative;
  z-index: 16;
`;

export const Typescript = styled.div`
  border: 0px;
  background-image: url(${typescript});
  width: 180px;
  height: 110px;
  background-size: cover;
  filter: blur(1px);
  position: relative;
  z-index: 17;
`;

export const Node = styled.div`
  border: 0px;
  background-image: url(${node});
  width: 180px;
  height: 110px;

  background-size: cover;
  filter: blur(1px);
  position: absolute;
  z-index: 13;
`;
