import React from "react";
import { Grid } from "./styles";
import MainHeader from "../MainHeader";
import Lside from "../Lside";
import Content from "../Content";

const Layout: React.FC = () => {
  return (
    <Grid>
      <Lside />
      <MainHeader />
      <Content />
    </Grid>
  );
};
export default Layout;
