import styled from "styled-components";

export const Container = styled.div`
  grid-area: MH;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  border-bottom: 0px solid;
  border-color: ${(props) => props.theme.colors.gray};
`;
export const Profile = styled.div`
  color: ${(props) => props.theme.colors.white};
`;
export const Welcome = styled.h3``;
export const UserName = styled.h2``;
