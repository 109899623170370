import React from "react";
import { ThemeProvider } from "styled-components";
import dark from "./styles/themes/dark";
import light from "./styles/themes/light";
import GlobatStyles from "./styles/GlobatStyles";
import Layout from "./components/Layout";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={dark}>
      <GlobatStyles />;
      <Layout />;
    </ThemeProvider>
  );
};

export default App;
