import { DefaultTheme } from "styled-components";

export default {
  title: "dark",
  colors: {
    primary: "#1B1F38",
    secondary: "#252A48",
    tertiary: "#313862",

    white: "#fff",
    black: "#000",
    gray: "#bfbfbf",

    success: "#4e41f0",
    info: "#f7931b",
    warning: "#e44c4e",
  },
};
