import React from "react";
import {
  Container,
  Header,
  MenuConteiner,
  MenuItemLink,
  Title,
} from "./styles";
import {
  MdSource,
  MdArrowUpward,
  MdArrowDownward,
  MdDone,
  MdOutlineEmail,
  MdPrivacyTip,
  MdSecurity,
} from "react-icons/md";

const Aside: React.FC = () => {
  return (
    <Container>
      <Header>
        <Title>Ronaldo Lamp</Title>
      </Header>
      <MenuConteiner>
        <MenuItemLink href="#">
          <MdSource />
          Portfólio
        </MenuItemLink>
        <MenuItemLink href="https://rc12.net/todo">
          <MdDone />
          Todo list
        </MenuItemLink>
        <MenuItemLink href="https://rc12.net/vela">
          <MdDone />
          Chat ao Vivo
        </MenuItemLink>
        <MenuItemLink href="https://rc12.net/politicaPrivacidade">
          <MdPrivacyTip />
          Política Privacidade
        </MenuItemLink>
        <MenuItemLink href="https://rc12.net/termosDeUso">
          <MdSecurity />
          Termos de Uso
        </MenuItemLink>
        <MenuItemLink href="#">
          <MdOutlineEmail />
          Contato: lamp@rc12.net
        </MenuItemLink>
      </MenuConteiner>
    </Container>
  );
};
export default Aside;
